import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49')
];

export const server_loads = [9,2];

export const dictionary = {
		"/(marketing)/(with-header)": [35,[9,11]],
		"/(marketing)/(with-header)/about": [36,[9,11]],
		"/(admin)/account/me": [~13,[2,3,4]],
		"/(admin)/account/me/danger": [14,[2,3,4]],
		"/(admin)/account/me/security": [15,[2,3,4]],
		"/(admin)/brand/[teamSlug]/(menu)": [~16,[2,5]],
		"/(admin)/brand/[teamSlug]/api": [23,[2]],
		"/(admin)/brand/[teamSlug]/(menu)/competitions/[id]": [~17,[2,5]],
		"/(admin)/brand/[teamSlug]/competitions/[id]/edit": [~24,[2]],
		"/(admin)/brand/[teamSlug]/(menu)/settings": [18,[2,5,6]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/billing": [~19,[2,5,6]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/integrations": [20,[2,5,6]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/members": [~21,[2,5,6]],
		"/(admin)/brand/[teamSlug]/(menu)/users": [~22,[2,5]],
		"/(marketing)/(with-header)/customers": [37,[9,11]],
		"/(giveaway)/c/[slug]": [~26,[8]],
		"/(giveaway)/c/[slug]/embed": [~27,[8]],
		"/(giveaway)/c/[slug]/terms-and-conditions": [~28,[8]],
		"/(giveaway)/demo/[slug]": [~29],
		"/(marketing)/features": [47,[9]],
		"/(marketing)/(auth)/forgot-password": [31,[9,10]],
		"/(marketing)/(with-header)/giveaway": [~38,[9,11]],
		"/(marketing)/(with-header)/guides": [~39,[9,11]],
		"/(marketing)/(with-header)/guides/[slug]": [~40,[9,11]],
		"/(marketing)/(with-header)/integrations": [41,[9,11]],
		"/(marketing)/invite/[id]": [~48,[9]],
		"/(admin)/onboarding": [25,[2,7]],
		"/(giveaway)/preview/[domain]": [~30],
		"/(marketing)/(with-header)/pricing": [42,[9,11]],
		"/(marketing)/(with-header)/privacy": [43,[9,11]],
		"/(marketing)/(auth)/sign-in": [32,[9,10]],
		"/sign-out": [~49],
		"/(marketing)/(auth)/sign-up": [33,[9,10]],
		"/(marketing)/(with-header)/support": [44,[9,11]],
		"/(marketing)/(with-header)/terms": [45,[9,11]],
		"/(marketing)/(auth)/update-password": [34,[9,10]],
		"/(marketing)/(with-header)/v3": [46,[9,11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';